<template>
    <div class="sysSet">
        <div class="main-flex">
            <edit-btns class="btn-box" 
                :bnts="['crear','goBack',{key:'recalculate',text:'成本重新核算',click:()=>{calculationIsShow=true;}}]"
                @crearClick="clearCache()"
                @goBackClick="$router.back()"/>
        </div>
        <div class="content-box">
            <div class="flex-box">
                <div class="left-table table-box">
                    <div class="title">归属分类选择</div>
                    <el-tree
                        ref="treeRef"
                        class="filter-tree"
                        :data="treeData"
                        :expand-on-click-node="false"
                        highlight-current
                        default-expand-all
                        @current-change="currentChange"
                    />
                </div>
                <div class="right-table ">
                    <div class="table-box">
                        <el-table style="width: 100%;height:100%"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                        :data="tableData"
                        :highlight-current-row="true"
                        @row-dblclick="dblclick"
                        :row-class-name="rowClassName"
                        >
                            <el-table-column fixed label="序号" width="50" type="index" />
                            <el-table-column prop="PARAMETER_NAME" label="参数名称" min-width="100" align="left">
                                <template #default="scope">
                                    <div :class="{groupChildren:(scope.row.PARAMETER_GROUP && (scope.$index>0 && tableData[scope.$index-1].PARAMETER_GROUP==scope.row.PARAMETER_GROUP))}">
                                        {{scope.row.PARAMETER_NAME}}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="PARAMETER_DESC" label="参数值" min-width="100" align="left"></el-table-column>
                            <el-table-column prop="PARAMETERKIND_NAME" label="归属分组" min-width="80" align="left"></el-table-column>
                            <el-table-column label="操作" width="80">
                                <template #default="scope">
                                    <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
        <modal class="parameterModal" :isShow="isParameterShow"  @keyup.esc="isParameterShow=false">
            <div class="header">修改参数</div>
            <div class="modal-body">
                <template v-for="data in editData" >
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE == 'text'">
                        <div class="lable-txt">{{data.name}}:</div>
                        <div class="from-input">
                            <input type="text" v-model="data.value"/>
                        </div>
                    </div>
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE == 'enum'">
                        <div class="lable-txt">{{data.name}}:</div>
                        <div class="from-input">
                            <el-select v-model="data.value" placeholder="请选择">
                                <el-option :value="item.value" :label="item.text" v-for="item in data.enumValues" :key="item"></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE == 'int'">
                        <div class="lable-txt">{{data.name}}:</div>
                        <div class="from-input">
                            <inputPattern v-model="data.value" type="number" pattern="number" :min="0" :dplaces="0"></inputPattern>
                        </div>
                    </div>
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE == 'color'">
                        <div class="lable-txt">{{data.name}}:</div>
                        <div class="from-input color-box">
                            <el-color-picker v-model="data.value" ref="color" size="small"/>
                            <input type="text" v-model="data.value" @click="colorClick" readonly/>
                        </div>
                    </div>
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE == 'date'">
                        <div class="lable-txt">{{data.name}}:</div>
                        <div class="from-input">
                            <el-date-picker class="from-date"
                            :clearable="false"
                            :editable="false"
                            v-model="data.value"
                            type="date"
                            placeholder=""
                            :default-value="new Date()">
                            </el-date-picker>
                        </div>
                    </div>
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE == 'bit'">
                        <div class="lable-txt">{{data.name}}:</div>
                        <div class="from-input">
                            <el-switch v-model="data.value" class="mb-2" :inline-prompt="true" inactive-text=""></el-switch>
                        </div>
                    </div>
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE == 'other_BasMoneyID_SingleChoice'">
                        <div class="lable-txt">{{data.name}}:</div>
                        <div class="from-input">
                            <!-- 付款方式 -->
                            <el-select v-model="data.value">
                                <el-option-group
                                    v-for="group in getPayTypeGroupFilte(data.optKind)"
                                    :key="group.PAYKIND_NAME"
                                    :label="group.PAYKIND_CODE+'-'+ group.PAYKIND_NAME"
                                    >
                                    <el-option
                                        v-for="item in group.PayTypes"
                                        :key="item.value"
                                        :label="item.PAY_CODE+'-'+item.PAY_NAME"
                                        :value="item.PAY_ID"
                                    >
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </div>
                    </div>
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE == 'other_BasMoneyID_MultipleChoice'">
                        <div class="lable-txt">{{data.name}}:</div>
                        <div class="from-input">
                            <!-- 付款方式 多选-->
                            <el-select v-model="data.value" multiple collapse-tags>
                                <el-option-group
                                    v-for="group in getPayTypeGroupFilte(data.optKind)"
                                    :key="group.PAYKIND_NAME"
                                    :label="group.PAYKIND_CODE+'-'+ group.PAYKIND_NAME"
                                    >
                                    <el-option
                                        v-for="item in group.PayTypes"
                                        :key="item.value"
                                        :label="item.PAY_CODE+'-'+item.PAY_NAME"
                                        :value="item.PAY_ID"
                                    >
                                    </el-option>
                                </el-option-group>
                            </el-select>
                        </div>
                    </div>
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE == 'key'">
                        <div class="lable-txt">{{data.name}}:</div>
                        <div class="from-input input-key">
                            <span class="key">Ctrl+Shift+Alt+</span><input type="text" v-model="data.value" @keyup="(e)=>keyup(e,data)" readonly/>
                        </div>
                    </div>
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE == 'enumother_BasMoneyID_SingleChoice'">
                        <div class="flex-box">
                            <div class="lable-name">{{data.name}}:</div>
                            <div class="btn" @click="add(data.tableData)">添加</div>
                            <div class="table-box">
                                <el-table style="width: 100%;height:200px"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                                :data="data.tableData"
                                >
                                    <el-table-column fixed type="index" label="序号" width="50" />
                                    <el-table-column fixed label="操作" width="40" align="center">
                                        <template #default="scope">
                                            <div class="operation">
                                                <b class="iconfont icon-jian" @click="del(data.tableData,scope.$index)"></b>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="渠道" min-width="100">
                                        <template #default="scope">
                                            <el-select v-model="scope.row.VALUEID1" placeholder="请选择">
                                                <el-option :value="item.value" :label="item.text" v-for="item in data.enumValues" :key="item"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="支付方式" min-width="100">
                                        <template #default="scope">
                                            <el-select v-model="scope.row.VALUEID2" placeholder="请选择">
                                                <el-option :value="item.PAY_ID" :label="item.PAY_CODE+'-'+item.PAY_NAME" v-for="item in getPayTypeFilte(50)" :key="item"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                    <div :key="data" v-if="data.PARAMETER_TYPE == 'custom_billNum'">
                        <div class="li">
                            <div class="lable-txt">{{data.name}}:</div>
                            <div class="from-input">
                                <el-select v-model="data.value" placeholder="请选择" @change="custom_billChange(data)">
                                    <el-option :value="item.value" :label="item.text" v-for="item in data.enumValues" :key="item"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div class="li" v-if="data.value==1">
                            <div class="lable-txt">开单号开始值>=</div>
                            <div class="from-input" v-if="data.tableData?.length>0" >
                                <input-pattern v-model="data.tableData[0].VALUEID2" pattern="number" :max="data.tableData[0].VALUEID3"></input-pattern>
                            </div>
                        </div>
                        <div class="li" v-if="data.value==1">
                            <div class="lable-txt">开单号最大值&lt;=</div>
                            <div class="from-input" v-if="data.tableData?.length>0" >
                                <input-pattern v-model="data.tableData[0].VALUEID3" pattern="number" :min="data.tableData[0].VALUEID2"></input-pattern>
                            </div>
                        </div>
                        <div class="li" v-if="data.value==2">
                            <div class="flex-box">
                            <div class="btn" @click="add(data.tableData,{VALUEID2:1000,VALUEID3:1000})">添加</div>
                            <div class="table-box">
                                <el-table style="width: 100%;height:200px"  v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}"
                                :data="data.tableData">
                                    <el-table-column fixed type="index" label="序号" width="50" />
                                    <el-table-column fixed label="操作" width="40" align="center">
                                        <template #default="scope">
                                            <div class="operation">
                                                <b class="iconfont icon-jian" @click="del(data.tableData,scope.$index)"></b>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="订单渠道" min-width="100">
                                        <template #default="scope">
                                            <el-select v-model="scope.row.VALUEID1" placeholder="请选择">
                                                <el-option :value="item.value" :label="item.text" v-for="item in channelData" :key="item"></el-option>
                                            </el-select>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label=">=开始值" min-width="80" >
                                        <template #default="scope">
                                            <input-pattern v-model="scope.row.VALUEID2" pattern="number" :max="scope.row.VALUEID3"></input-pattern>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="<=最大值" min-width="80" pattern="number">
                                        <template #default="scope">
                                            <input-pattern v-model="scope.row.VALUEID3" pattern="number" :min="scope.row.VALUEID2"></input-pattern>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="渠道标识" min-width="80">
                                        <template #default="scope">
                                            <input type="text" v-model="scope.row.VALUEID4"/>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="li" :key="data" v-if="data.PARAMETER_TYPE=='other_EatXFKind'">
                        <div class="lable-txt">{{data.name}}:</div>
                        <div class="from-input">
                            <select-tree v-model="data.value" placeholder="请选择" :data="treeEatXFKind" title="菜品分类选择"/>
                        </div>
                    </div>
                </template>
            </div>
            <div class="footer-box">
                <button class="btn btn-cancel" @click="isParameterShow=false">取消</button>
                <button class="btn btn-confirm" @click="confirm()">确定</button>
            </div>
        </modal>

        <!-- 成本重新核算 -->
        <modal-load :isShow="calculationIsShow" >
            <calculation-model :isShow="calculationIsShow" @close="calculationIsShow=false"></calculation-model>
        </modal-load>
    </div>
</template>

<script>
import calculationModel from './calculationModel/calculationModel.vue'
export default {
    name:"sysSet",
    components:{
        calculationModel
    },
    data(){
        return {
            //门店
            Department_Name:'',
            //编辑 弹层
            isParameterShow:false,
            /**付款方式数据 */
            payTypeList:[],
            /**渠道 */
            OrderChannel:[],
            cacheData:[],
            //数据
            parametersKind:[],
            currentNode:null,
            //需要修改的数据
            editData:[],
            //成本重新核算 弹层
            calculationIsShow:false,
            /**菜品数据 */
            posFoodData:[],
        }
    },
    computed:{
        //分类数据
        treeData(){
            let data=[];
            this.parametersKind.forEach(node=>{
                data.push({
                    id:node.PARAMETERKIND_ID,
                    label:node.PARAMETERKIND_NAME,
                    parentid:node.PARAMETERKIND_PARENTID,
                    children:node.NodeList?.map(item=>{
                        return {
                            id:item.PARAMETERKIND_ID,
                            label:item.PARAMETERKIND_NAME,
                            parentid:node.PARAMETERKIND_ID
                        }
                    })
                })
            })
            return [{
                id:-1,
                label:"全部分类",
                children:data
            }];
        },
        tableData(){
            let data=[];
            if(!this.currentNode || this.currentNode.id==-1){
                this.parametersKind.forEach(node=>{
                    node.NodeList?.forEach(item=>{
                        if(item.posSysParameters && item.posSysParameters.length>0) data=data.concat(item.posSysParameters);
                    })
                })
            }else if(this.currentNode.parentid==0){
                this.parametersKind.find(node=>{
                    if(node.PARAMETERKIND_ID==this.currentNode.id){
                        node.NodeList?.forEach(item=>{
                            if(item.posSysParameters && item.posSysParameters.length>0) data=data.concat(item.posSysParameters);
                        })
                        return true;
                    }
                    return false;
                })
            }else{
                this.parametersKind.find(node=>node.NodeList?.findIndex(item=>{
                    if(item.PARAMETERKIND_ID==this.currentNode.id){
                        data=item.posSysParameters||[];
                        return true;
                    }
                    return false;
                })>=0)
            }
            return data.filter(it=>it.PARAMETER_TYPE!="img");
        },
        //渠道
        channelData(){
            let data=[];
            this.OrderChannel?.forEach((d)=>{
                d.OrderChannelList?.forEach(it=>{
                    data.push({
                        text:it.OrderChannel_Name,
                        value:it.OrderChannel_AutoID
                    })
                })
            })
            return data;
        },
        //菜品分类数据
        treeEatXFKind(){
            let foodMainCategory=[];
            this.posFoodData?.forEach(item=>{
                let depart={
                    id:item.MainCategory_ID,
                    name:item.MainCategory_Name,
                    children:[],
                    Hierarchy:1
                }
                if(item.FoodSubCategorys){
                    item.FoodSubCategorys.forEach(eat=>{
                        let chieat={
                            id:eat.SubCategory_ID,
                            name:eat.SubCategory_Name,
                            Hierarchy:2
                        }
                        depart.children.push(chieat)
                    })
                }
                foodMainCategory.push(depart)
            })
            return [{
                id:1,
                name:"全选",
                children:foodMainCategory
            }];
        },
    },
    mounted(){
        this.$emit("navIndex",4);
        let userInfo= this.$auth.getUserInfo();
        if(userInfo){
            this.Department_Name=userInfo.Department_Name;
        }
        //出单位置数据
        this.$cacheData.AllPayTypes().then((d)=>{
            this.payTypeList=d;
        }).catch(e=>{
            console.log('付款方式数据获取失败:'+e)
        })
        //渠道
        this.$cacheData.OrderChannel().then((d)=>{
            this.OrderChannel=d;
        }).catch(e=>{
            console.log('渠道数据获取失败:'+e)
        })
        //菜品数据结构
        this.$cacheData.AllPosFoods().then((d)=>{
            this.posFoodData=d;
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        this.loadData();
    },
    methods:{
        loadData(){
            const loading = this.$loading({
                text: "加载门店基础参数数据...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.GetPosSysParameter",{
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                console.log(d)
                if(d.ResponseHeader.ResultCode==0){
                    this.parametersKind=d.ResponseBody||[];
                    this.parametersKind.forEach(node=>{
                        node.NodeList?.forEach(item=>{
                            item.posSysParameters?.forEach(p=>{
                                p.PARAMETERKIND_NAME=item.PARAMETERKIND_NAME;
                            });
                        })
                    })
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('未找到门店基础参数数据：'+e.message);
                console.log('未找到门店基础参数数据：',e);
            })
        },
        currentChange(node){
            if(node.id!=0){
                this.currentNode=node;
            }else{
                this.currentNode=null;
            }
            
        },
        keyup(e,data){
            e.preventDefault();
            if(e.key== "Backspace"){
                data.value="";
            }
            else if (e.key!= "Control" && e.key!= "Alt" && e.key!= "Shift") {
                if(e.keyCode==32){
                    data.value="Space".toLocaleUpperCase();
                }else if(e.key=="ArrowUp"){
                    data.key="↑";
                }else if(e.key=="ArrowDown"){
                    data.key="↓";
                }else if(e.key=="ArrowLeft"){
                    data.key="←";
                }
                else if(e.key=="ArrowRight"){
                    data.key="→";
                }else{
                    data.value=e.key.toLocaleUpperCase();
                }
            }
        },
        getPayTypeGroupFilte(type){
            if(this.cacheData.payTypeGroup && this.cacheData.payTypeGroup[type]){
                return this.cacheData.payTypeGroup[type];
            }
            let data=[];
            this.payTypeList.forEach(t=>{
                let paytype=[];
                t.PayTypes.forEach(p=>{
                    if(p.INTERFACE_TYPE==type || !type){
                        paytype.push(p);
                    }
                })
                if(paytype.length>0){
                    data.push({
                        PAYKIND_ID:t.PAYKIND_ID,
                        PAYKIND_CODE:t.PAYKIND_CODE,
                        PAYKIND_NAME:t.PAYKIND_NAME,
                        PayTypes:paytype
                    });
                }
            });
            if(!this.cacheData.payTypeGroup){
                this.cacheData.payTypeGroup={};
            }
            this.cacheData.payTypeGroup[type]=data;
            return data;
        },
        getPayTypeFilte(type){
            if(this.cacheData.payType && this.cacheData.payType[type]){
                return this.cacheData.payType[type];
            }
            let data=[];
            this.payTypeList.forEach(t=>{
                data=data.concat(t.PayTypes.filter(p=>p.INTERFACE_TYPE==type)||[]);
            });
            if(!this.cacheData.payType){
                this.cacheData.payType={};
            }
            this.cacheData.payType[type]=data;
            return data;
        },
        add(table,defaultJson){
            if(!table) return;
            table.push(defaultJson||{});
        },
        del(table,index){
            if(!table) return;
            table.splice(index,1);
        },
        rowClassName({row,rowIndex}){
            if(row.PARAMETER_GROUP && (rowIndex==0 || (rowIndex>0 && this.tableData[rowIndex-1].PARAMETER_GROUP!=row.PARAMETER_GROUP))){
                return "row-group";
            }
        },
        /**双击 某一行*/
        dblclick(row, column){
            if(column.label!= "操作"){
                this.handleClick(row);
            }
        },
        /**点击操作 */
        handleClick(row){
            let paramsData=[];
            if(row.PARAMETER_GROUP){
                paramsData=this.tableData.filter(t=>t.PARAMETER_GROUP==row.PARAMETER_GROUP);
            }else{
                paramsData=[row];
            }
            let editD=[]
            paramsData.forEach(t=>{
                console.log(t);
                let value=t.PARAMETER_VALUE?t.PARAMETER_VALUE:t.DEFAULTVALUE;
                let json={
                    PARAMETERKIND_ID:t.PARAMETERKIND_ID,
                    PARAMETER_TYPE:t.PARAMETER_TYPE,
                    PARAMETER_ID:t.PARAMETER_ID,
                    value:value,
                    name:t.PARAMETER_NAME
                };
                if(t.PARAMETER_TYPE=="enum"){
                    let values = eval(t.ENUM_VALUE||[]);
                    json.enumValues=values;
                    if(!isNaN(json.value)){
                        json.value=parseFloat(json.value);
                    }else{
                        json.value=(json.value||"").trim();
                    }
                }
                else if(t.PARAMETER_TYPE=="bit"){
                    json.value=json.value==1;
                }
                else if(t.PARAMETER_TYPE=='other_EatXFKind' || t.PARAMETER_TYPE=="other_BasMoneyID_SingleChoice" || t.PARAMETER_TYPE=="other_BasMoneyID_MultipleChoice"){
                    let condition={};
                    if(t.PARAMETER_CONDITION){
                        try {
                            condition=JSON.parse(t.PARAMETER_CONDITION);
                        } catch (error) {
                            console.log(error)
                        }
                    }
                    json.optKind=condition.optKind||"";
                    if(isNaN(json.value)){
                        json.value=(json.value||"").trim();
                    }
                    if(t.PARAMETER_TYPE=="other_BasMoneyID_MultipleChoice" || t.PARAMETER_TYPE=='other_EatXFKind'){//多选
                        json.value=(json.value||"").split(",").filter(it=>it);
                    }
                    
                }else if(t.PARAMETER_TYPE=='key'){
                    json.value=json.value?.replace("Ctrl+Shift+Alt+","");
                }else if(t.PARAMETER_TYPE=="enumother_BasMoneyID_SingleChoice"){
                    let values = eval(t.ENUM_VALUE||[]);
                    json.enumValues=values;
                    json.tableData=[];

                    t.PARAMETERRELATIONLIST?.forEach(t=>{
                        json.tableData.push({
                            AUTOID: t.AUTOID,
                            RELATIONID: t.RELATIONID,
                            VALUEID1: isNaN(t.VALUEID1)?t.VALUEID1:parseFloat(t.VALUEID1),
                            VALUEID2: t.VALUEID2,
                        })
                    })
                }
                else if(t.PARAMETER_TYPE=="custom_billNum"){
                    let values = eval(t.ENUM_VALUE||[]);
                    json.enumValues=values;
                    if(!isNaN(json.value)){
                        json.value=parseFloat(json.value);
                    }else{
                        json.value=(json.value||"").trim();
                    }
                    json.tableData=[];
                    t.PARAMETERRELATIONLIST?.forEach(t=>{
                        json.tableData.push({
                            AUTOID: t.AUTOID,
                            RELATIONID: t.RELATIONID,
                            VALUEID1: t.VALUEID1,
                            VALUEID2: t.VALUEID2,
                            VALUEID3: t.VALUEID3,
                            VALUEID4: t.VALUEID4,
                        })
                    })
                    
                    if(json.value==1 && json.tableData.length==0){
                        json.tableData=[{VALUEID2:1000,VALUEID3:1000}];
                    }
                }
                editD.push(json);
            })
            console.log(editD)
            this.editData=editD;
            this.isParameterShow=true;
        },
        custom_billChange(item){
            if(item.value==1){
                item.tableData=[{VALUEID2:1000,VALUEID3:1000}];
            }else if(item.value==2){
                item.tableData=[];
            }
        },
        confirm(){
            let infoList=[];
            let error="";
            this.editData.forEach(t=>{
                let json={
                    PARAMETERKIND_ID:t.PARAMETERKIND_ID,
                    PARAMETER_TYPE:t.PARAMETER_TYPE,
                    PARAMETER_ID:t.PARAMETER_ID,
                    PARAMETER_VALUE:t.value?.toString(),
                    PARAMETER_DESC:t.value
                }
                if(t.PARAMETER_TYPE == 'date'){
                    json.PARAMETER_DESC = json.PARAMETER_VALUE=(new Date(t.value)).Format('yyyy-MM-dd');
                }
                else if(t.PARAMETER_TYPE=="enumother_BasMoneyID_SingleChoice" || t.PARAMETER_TYPE=="enumother_Phase_SingleChoice"){
                    json.PARAMETERRELATIONLIST=t.tableData;
                    json.PARAMETER_DESC=json.PARAMETER_VALUE;
                }else if(t.PARAMETER_TYPE=="key"){
                    if(t.value) json.PARAMETER_VALUE="Ctrl+Shift+Alt+"+t.value;
                    json.PARAMETER_DESC=json.PARAMETER_VALUE;
                }else if(t.PARAMETER_TYPE=="enum"){

                    json.PARAMETER_DESC=t.enumValues?.find(it=>it.value==t.value)?.text;
                }else if(t.PARAMETER_TYPE=="bit"){
                    json.PARAMETER_VALUE=t.value?"1":"0"
                    json.PARAMETER_DESC=t.value?"开启中" : "关闭中";
                }
                else if(t.PARAMETER_TYPE=="custom_billNum"){
                    json.PARAMETERRELATIONLIST=t.tableData;
                    json.PARAMETER_DESC=t.enumValues[t.value].text;
                    if(t.value==1){
                        if (parseFloat(t.tableData[0].VALUEID2||0) >= parseFloat(t.tableData[0].VALUEID3||0)) {
                            error = "开始值必须小于最大值！";
                        }
                    }else if(t.value==2){
                        let channelid = {};
                        for (var i = 0; i < t.tableData.length; i++) {
                            if (channelid[t.tableData[i].VALUEID1]) {
                                error = "渠道不能重复！";
                                break;
                            }else{
                                channelid[t.tableData[i].VALUEID1]=true;
                            }
                            if (parseFloat(t.tableData[i]["VALUEID2"]||0) >= parseFloat(t.tableData[i]["VALUEID3"]||0)) {
                                error = "开始值必须小于最大值！";
                                break;
                            }
                        }
                        
                    }
                }else if(t.PARAMETER_TYPE=="other_BasMoneyID_MultipleChoice"){// 多选 支付方式
                    json.PARAMETER_DESC="点击查看详情";
                }else if(t.PARAMETER_TYPE=='other_EatXFKind'){// 多选 菜品分类
                    json.PARAMETER_DESC="点击查看详情";
                }
                infoList.push(json);
            })
            if(error){
                this.$message.warning(error);
                return;
            }
            if(!infoList && infoList.length==0){
                this.$message.warning("pos系统参数设置数据不能为空!");
                return;
            }
            
            const loading = this.$loading({
                text: "数据保存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.SavePosSysParameter",{
                PosSysParameterList:infoList,
                Operator_Name:userInfo?.Login_Name,
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("保存成功");
                    this.isParameterShow=false;

                    infoList.forEach(info=>{
                        let t=this.tableData.find(t=>info.PARAMETER_ID==t.PARAMETER_ID);
                        if(t){
                            t.PARAMETER_DESC=info.PARAMETER_DESC;
                            t.PARAMETER_VALUE=info.PARAMETER_VALUE;
                            t.PARAMETERRELATIONLIST=info.PARAMETERRELATIONLIST;
                        }
                    });

                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('数据保存失败：'+e);
                console.log('数据保存失败：',e);
            })
        },
        /**清除缓存 */
        clearCache(){
            const loading = this.$loading({
                text: "清除缓存中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.ClearCache",{
                User_ID:this.$auth.getUserID(),
                CacheData_Type:0
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                     this.$message.success("清除缓存成功");
                     this.loadData();
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('清除缓存失败：'+e.message);
                console.log('清除缓存失败：',e);
            })
        },
        /**成本重新核算 */
        recalculate(){

        },
        colorClick(){
            this.$refs.color.handleTrigger();
        }
    }
}
</script>

<style lang="scss">
@import './sysSet.scss'
</style>